(function(){
"use strict";
angular.module('DS.ngJS', []);

})();
(function(){
	angular.module('DS.ngJS')
		.directive('maskCep', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {
				if(attrs.maskCep == 'true') {
					ctrl.validate = function(viewValue){
						ctrl.$setValidity("cep_invalid", true);

						if(!viewValue) return '';

						viewValue = viewValue.replace(/\D/g,"").substr(0, 8);

						var string = viewValue.replace(/\D/g,"");

						if(viewValue.length == 8){
							string = string.replace(/^(\d{5})(\d{3})/g,"$1-$2");
						}else if(viewValue.length <= 5){
							string = string;
						}else if(viewValue.length > 5){
							string = string.replace(/^(\d{5})(\d)/g,"$1-$2");
						}

						element.val(string);

						var value = viewValue.replace(/\D/g,'');

						if(value.length != 0){
							ctrl.$setValidity("cep_invalid", false);

							if(value.length == 8){
								ctrl.$setValidity("cep_invalid", true);
								return value;
							}

							return undefined;
						}else{
							ctrl.$setValidity("cep_invalid", true);
							return undefined;
						}
					};

					ctrl.$formatters.unshift(function (a) {
						if(!ctrl.$modelValue) return '';

						var string = ctrl.$modelValue.toString().replace(/\D/g,"");

						if(string.length == 8){
							string = string.replace(/^(\d{5})(\d{3})/g,"$1-$2");
						}else if(string.length <= 5){
							string = string;
						}else if(string.length > 5){
							string = string.replace(/^(\d{2})(\d)/g,"$1-$2");
						}

						return string;
					});

					ctrl.$parsers.unshift(function(viewValue) {
						return ctrl.validate(viewValue);
					});
				}
			}
		};
	});
})();
(function(){
	/**
	* Mascara de CPF
	*/
	angular.module('DS.ngJS')
		.directive('maskCpf', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {

				if(attrs.maskCpf == 'true') {
					ctrl.validate = function(viewValue){
						ctrl.$setValidity("cpf_invalid", true);
						if(!viewValue) return '';

						viewValue = viewValue.replace(/\D/g,"").substr(0, 11);

						var string = viewValue.replace(/\D/g,"");

						if(viewValue.length == 11){
							string = string.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1-$2-$3.$4");
						}else if(viewValue.length > 9){
							string = string.replace(/^(\d{3})(\d{3})(\d{3})(\d)/g,"$1-$2-$3.$4");
						}else if(viewValue.length > 6){
							string = string.replace(/^(\d{3})(\d{3})(\d)/g,"$1-$2-$3");
						}else if(viewValue.length > 3){
							string = string.replace(/^(\d{3})(\d)/g,"$1-$2");
						}

						element.val(string);

						var value = viewValue.replace(/\D/g,'');

						if(value.length != 0){
							ctrl.$setValidity("cpf_invalid", false);

							if(value.length == 11){
								if(testCPF(value)){
									ctrl.$setValidity("cpf_invalid", true);
								}else{
									ctrl.$setValidity("cpf_invalid", false);
								}

								return value;
							}

							return undefined;
						}else{
							ctrl.$setValidity("cpf_invalid", true);
							return undefined;
						}
					};

					ctrl.$formatters.unshift(function (a) {
						ctrl.$setValidity("cpf_invalid", true);

						if(!ctrl.$modelValue) return '';

						var string = ctrl.$modelValue.toString().replace(/\D/g,"");
						if(string.length == 11){
							string = string.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4");
						}else if(string.length > 9){
							string = string.replace(/^(\d{3})(\d{3})(\d{3})(\d)/g,"$1.$2.$3-$4");
						}else if(string.length > 6){
							string = string.replace(/^(\d{3})(\d{3})(\d)/g,"$1.$2.$3");
						}else if(string.length > 3){
							string = string.replace(/^(\d{3})(\d)/g,"$1.$2");
						}

						var value = string.replace(/\D/g,'');
						if(value.length != 0){
							ctrl.$setValidity("cpf_invalid", false);

							if(value.length == 11){
								if(testCPF(value)){
									ctrl.$setValidity("cpf_invalid", true);
								}else{
									ctrl.$setValidity("cpf_invalid", false);
								}

								return string;
							}
						}

						return string;
					});

					ctrl.$parsers.unshift(function(viewValue) {
						return ctrl.validate(viewValue);
					});

					function testCPF(strCPF) {
						var Soma;
						var Resto;
						Soma = 0;
						if (strCPF == "00000000000") return false;
						if (/^(.)\1+$/.test(strCPF)) return false;

						for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
							Resto = (Soma * 10) % 11;

						if ((Resto == 10) || (Resto == 11))  Resto = 0;
						if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

						Soma = 0;
						for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
							Resto = (Soma * 10) % 11;

						if ((Resto == 10) || (Resto == 11))  Resto = 0;
						if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
						return true;
					}
				}
			}
		};
	});
})();
(function(){
	angular.module('DS.ngJS')
	.directive('maskDate', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {

				if(attrs.maskDate == 'true') {
					ctrl.$formatters.unshift(function (a) {
						if(!ctrl.$modelValue) return '';

						var string = ctrl.$modelValue.replace(/\D/g,"");

						if(string.length == 8){
							string = string.replace(/^(\d{2})(\d{2})(\d{4})/g,"$1/$2/$3");
						}else if(string.length <= 2){
							string = string;
						}else if(string.length <= 4){
							string = string.replace(/^(\d{2})(\d)/g,"$1/$2");
						}else if(string.length <= 8){
							string = string.replace(/^(\d{2})(\d{2})(\d)/g,"$1/$2/$3");
						}

						return string;
					});

					ctrl.$parsers.unshift(function(viewValue) {
						viewValue = viewValue.replace(/\D/g,"").substr(0, 8);

						var string = viewValue.replace(/\D/g,"");

						if(viewValue.length == 8){
							string = string.replace(/^(\d{2})(\d{2})(\d{4})/g,"$1/$2/$3");
						}else if(viewValue.length <= 2){
							string = string;
						}else if(viewValue.length <= 4){
							string = string.replace(/^(\d{2})(\d)/g,"$1/$2");
						}else if(viewValue.length <= 8){
							string = string.replace(/^(\d{2})(\d{2})(\d)/g,"$1/$2/$3");
						}

						element.val(string);

						var value = viewValue.replace(/\D/g,'');

						if(value.length != 0){
							ctrl.$setValidity("date_invalid", false);

							if(value.length == 8){

								ctrl.$setValidity("date_invalid", true);

								var day = value.substr(0,2);
								var monthy = value.substr(2,2);
								var year = value.substr(4,4);

								//Dia
								if(day < 0 || day > 31){
									ctrl.$setValidity("date_invalid", false);
								}
								//Mês
								if(monthy < 1 || monthy > 12){
									ctrl.$setValidity("date_invalid", false);
								}

								return value;
							}

							return undefined;
						}else{
							ctrl.$setValidity("date_invalid", true);
							return undefined;
						}
					});
				}
			}
		};
	});
})();
(function(){
	angular.module('DS.ngJS')
		.directive('maskEmail', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {

				if(attrs.maskEmail == 'true') {
					ctrl.$parsers.unshift(function(viewValue) {
						var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

						if(viewValue.length != 0){
							ctrl.$setValidity("email_invalid", false);
							if(re.test(String(viewValue).toLowerCase())){
								ctrl.$setValidity("email_invalid", true);
								return viewValue;
							}
							return undefined;
						}else{
							ctrl.$setValidity("email_invalid", true);
							return viewValue;
						}
					});
				}

			}
		};
	});
})();
(function() {
	"use strict";
	angular.module('DS.ngJS')
	.directive("maskPass", function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {
				if(attrs.maskPass == 'true') {
					ctrl.validate = function(viewValue){
						ctrl.$setValidity("password_length_invalid", true);

						if(!viewValue) return '';

						var value = viewValue;

						if(value.length != 0){
							ctrl.$setValidity("password_length_invalid", true);

							if(value.length <= 6){
								ctrl.$setValidity("password_length_invalid", false);
								return undefined;
							}else if(value.length >= 12){
								ctrl.$setValidity("password_length_invalid", false);
								return undefined;
							}
							return value;

						}else{
							ctrl.$setValidity("password_length_invalid", true);
							return undefined;
						}
					};

					ctrl.$formatters.unshift(function (a) {
						if(!ctrl.$modelValue) return '';

						var string = ctrl.$modelValue.toString().replace(/\D/g,"");

						if(string.length == 6){
							string = string.replace(/^(\d{5})(\d{3})/g,"$1-$2");
						}else if(string.length <= 5){
							string = string;
						}else if(string.length > 5){
							string = string.replace(/^(\d{2})(\d)/g,"$1-$2");
						}

						return string;
					});

					ctrl.$parsers.unshift(function(viewValue) {
						return ctrl.validate(viewValue);
					});
				}
			}
		};
	});
})();
(function(){
	angular.module('DS.ngJS')
	.directive('maskPhone', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {

				if(attrs.maskPhone == 'true') {
					ctrl.$parsers.unshift(function(viewValue) {
						if(viewValue.length > 11){
							viewValue = viewValue.replace(/\D/g,"").substr(0, 11);
						}

						var string = viewValue.replace(/\D/g,"");

						if(string.length == 11){
							string = string.replace(/^(\d{2})(\d{5})(\d{4})/g,"($1) $2-$3");
						}else if(string.length > 10){
							string = string.replace(/^(\d{2})(\d{4})(\d{4})/g,"($1) $2-$3");
						}else if(string.length > 6){
							string = string.replace(/^(\d{2})(\d{4})(\d)/g,"($1) $2-$3");
						}else if(string.length > 2){
							string = string.replace(/^(\d{2})(\d)/g,"($1) $2");
						}

						element.val(string);

						var value = viewValue.replace(/\D/g,'');

						if(value.length != 0){
							ctrl.$setValidity("phone_invalid", false);
							if(value.length >= 10){
								ctrl.$setValidity("phone_invalid", true);
								return value;
							}
							return undefined;
						}else{
							ctrl.$setValidity("phone_invalid", true);
							return value;
						}
					});

					ctrl.$formatters.unshift(function (a) {
						if(!ctrl.$modelValue) return '';

						var string = ctrl.$modelValue.replace(/\D/g,"");

						if(string.length == 11){
							string = string.replace(/^(\d{2})(\d{5})(\d{4})/g,"($1) $2-$3");
						}else if(string.length > 10){
							string = string.replace(/^(\d{2})(\d{4})(\d{4})/g,"($1) $2-$3");
						}else if(string.length > 6){
							string = string.replace(/^(\d{2})(\d{4})(\d)/g,"($1) $2-$3");
						}else if(string.length > 2){
							string = string.replace(/^(\d{2})(\d)/g,"($1) $2");
						}

						return string;
					});
				}
			}
		};
	});
})();

(function(){

    /**
     * Serviço para bloquear páginas que necessitam o login no UiRouter
     *
     * Exemplo de uso:
     * Adicionar nas rotas

    {
        name: 'route',
        url: '/route',
        resolve: {
            auth: DSauthProvider.redirectIfNotAuthenticated
        },
        template: 'route'
    }

    Ativar/Desativar Autentificação
    DSauth.setAuthenticated(true);

     */
    angular.module('DS.ngJS')
        .provider('DSauth', function AuthProvider() {
            var vm = this;

            vm.isAuthenticated = false;

            /**
             * Redireciona caso não esteja logado
             */
            vm.redirectIfNotAuthenticated = function($q, $timeout, $http, $state, DSauth) {
                var deferred = $q.defer();

                $timeout(function () {
                    if(DSauth.isAuthenticated()){
                        return deferred.resolve(true);
                    }else{
                        $timeout(function () {
                            $state.go('login');
                        });
                        return deferred.reject();
                    }
                });

                return deferred.promise;
            };

            //
            vm.$get = function(){
                return {
                    setAuthenticated: function(b){
                        vm.isAuthenticated = b;
                    },
                    isAuthenticated: function(){
                        return vm.isAuthenticated;
                    }
                };
            };
        });
})();

(function(){

    /**
     * Serviço para facilitar o uso da API Restfull
     *
     * Examplo de uso:

    .factory('Users', function($DSrest) {
        return new $DSrest('https://api.example.com.br', '/v1/accounts');
    });

     */
    angular.module('DS.ngJS')
        .service("$DSrest", ["$http", function($http){
            return function(url, path) {
                var vm = this;

                //Valor do recurso atual
                vm.currentID = false;
                vm.url = url;
                vm.path = path;

                /**
                 *
                 * Resgata recurso pelo ID
                 *
                 */
                vm.$get = function(id){
                    //Resgata ID
                    if(typeof id == 'undefined'){
                        if(vm.currentID !== false){
                            id = vm.currentID;
                        }else{
                            return false;
                        }
                    }

                    //Atualizar valor do ID
                    vm.currentID = id;

                    var current_path = vm.url+vm.path+'/'+id;
                    return $http.get(current_path)
                            .then(function(response){
                                return response.data;
                            }, function(response) {
                                return response.data;
                            });
                };

                /**
                 *
                 * Busca recursos
                 * {path}+/search
                 *
                 * Valores que podem ser passados
                 * limit, offset, sort, filters
                 *
                 */
                vm.$search = function(data){

                    var current_path = vm.url+vm.path+'/search';

                    var params = {};
                    params.limit = data.limit || 20;
                    params.offset = data.offset || 20;
                    params.sort = data.sort || undefined;

                    var query = [];
                    for (var i in params) {
                        if(typeof params[i] != 'undefined'){
                            query.push(i+'='+params[i]);
                        }
                    }

                    if(!!query.join('&')){
                        current_path += '?'+query.join('&');
                    }

                    data.filters = data.filters || {};

                    return $http.post(current_path, data.filters)
                            .then(function(response){
                                 return response.data;
                            }, function(response) {
                                return response.data;
                            });
                };

                /**
                 *
                 * Cria recurso
                 *
                 */
                vm.$create = function(data){

                    var current_path = vm.url+vm.path;

                    return $http.post(current_path, data)
                            .then(function(response){
                                 return response.data;
                            }, function(response) {
                                return response.data;
                            });
                };

                /**
                 *
                 * Atualiza recurso
                 *
                 */
                vm.$update = function(id, data){
                    //
                    // Verifica se já existe um ID na class e usa caso não seja passada nada no parametro id
                    // Verifica se o id é um objeto e passa esse valor para o data
                    //
                    if(typeof id == 'undefined' || typeof id == 'object'){
                        if(typeof id == 'object'){
                            data = id;
                        }

                        if(vm.currentID !== false){
                            id = vm.currentID;
                        }else{
                            return false;
                        }
                    }

                    //Atualizar valor do ID
                    vm.currentID = id;

                    var current_path = vm.url+vm.path+'/'+id;

                    return $http.post(current_path, data)
                            .then(function(response){
                                return response.data;
                            }, function(response) {
                                return response.data;
                            })
                            .catch(function(response){
                                return response.data;
                            });
                };

                /**
                 *
                 * Deleta recurso
                 *
                 */
                vm.$delete = function(id){
                    //Resgata ID
                    if(typeof id == 'undefined'){
                        if(vm.currentID !== false){
                            id = vm.currentID;
                        }else{
                            return false;
                        }
                    }

                    //Atualizar valor do ID
                    vm.currentID = id;

                    var current_path = vm.url+vm.path+'/'+id;

                    return $http.delete(current_path)
                            .then(function(response){
                                 return response.data;
                            }, function(response) {
                                return response.data;
                            });
                };

                return vm;
            };
        }]);
})();
